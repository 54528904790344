import { ButtonStyle, Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import React, { FC, ReactNode } from 'react'

type FormProps = {
	children: ReactNode
}

type Classes = {
	button: string
}

const classes: Classes = makeClasses({
	button: {
		...ButtonStyle,
		background: Colors.secondary,
		borderColor: Colors.secondary,
		// borderRadius: '50%',
		color: Colors.white,
		padding: '15px',
		'&:hover': {
			cursor: 'pointer'
		}
	}
})

const Form: FC<FormProps> = ({ children }) => {
	return (
		<>
			<form>
				{children}
				<div>
					<button type="submit" className={classes.button}>
						Submit
					</button>
				</div>
			</form>
		</>
	)
}

export default Form
